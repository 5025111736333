#learning-show{
    #tile-paper-header{
        height: 15em;
        width: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .article-header{
        background-position: center;
        height: 13em;
        background-repeat: no-repeat;
        background-size: 100%;
    }
    #tile-paper-content{
        background: darken(white, 3%);
        .paper-date{
            display: block;
            font-weight: 300;
            font-size: .8em;
            color: lighten(black, 50%);
            margin-bottom: .5em;
            font-style: italic;
        }
        h2{
            margin-top: 1.5em;
            font-family: $oswald;
            font-size: 1.25em;
        }
        ul{
            margin: 1em 0em 2em 2em;
            li{
                margin-bottom: 1em;
                font-size: 1.15em;
                list-style-type: disc;
                color: lighten(black, 40%);
                font-family: $quattrocento;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    @media screen and (max-width: 1150px) {
        .article-header{
            background-size: 125%;
        }
    }
    @media screen and (max-width: 950px) {
        .article-header{
            background-size: 150%;
            height: 10em;
        }
    }
    @media screen and (max-width: 750px) {
        .article-header{
            background-size: 950px;
        }
    }
}
