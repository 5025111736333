$oswald: 'Oswald', sans-serif;
$ubuntu: 'Ubuntu', sans-serif;
$quattrocento: 'Quattrocento', serif;

$dark: #262626;
$green: #3eb34f;
$fb: #3b5998;
$tw: #00aced;
$gp: #dd4b39;
$li: #007bb6;

$australiandream: #b61f1f;
$tricalm: #172740;
$willowcurve: #202629;
$smartmouth: #0072C5;
