#casestudy-show{
    .contain-text{
        max-width: 800px;
        margin: 0 auto;
    }
    p{
        font-family: $ubuntu;
        font-size: 80%;
        font-weight: 300;
    }
    h1{
        color: white;
        font-size: 2em;
        font-weight: 300;
        margin-bottom: .05em;
    }
    h2{
        text-transform: uppercase;
        font-size: 1.75em;
        margin-bottom: .75em;
        &.invert{
            color: white;
        }
    }
    .left-asset{
        width: 70%;
        display: inline-block;
        img{
            width: 100%;
        }
    }
    .right-asset{
        display: inline-block;
        position: absolute;
        width: 22em;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        height: 16em;
        right: 0;
    }
    .left-info, .right-info{
        display: inline-block;
    }
    .left-info{
        width: 40%;
        float: left;
        padding-left: 0;
    }
    .right-info{
        padding-left: 2em;
        padding-top: 2em;
        float: right;
    }
    .video{
        display: inline-block;
    }
    .mock-asset{
        position: absolute;
        right: 0;
        width: 26em;
        top: 0;
        img{
            width: 100%;
        }
    }
    .intro-tile{
        padding: 2em;
        background: $australiandream;
        min-height: 315px;
        @include angle(after, false, 4deg);
        p{
            color: white;
        }
        .copy-wrapper{
            width: 525px;
        }
    }
    .title-label{
        display: block;
        text-transform: uppercase;
        color: white;
        margin-bottom: .25em;
        font-size: .8em;
    }
    ul.services-list{
        margin-bottom: 1em;
        li{
            display: inline-block;
            font-size: .8em;
            margin-right: .25em;
            color: white;
            font-weight: 500;
            &:after{
                content: "/";
                margin-left: .5em;
            }
            &:last-child{
                margin-right: 0;
                &:after{
                    content: "";
                }
            }
        }
    }
    .info-tile{
        padding: 7em 2em;
        background-image: url('/img/casestudies/australian-dream/pharmacy-background.png');
        background-size: 35%; 
        background-position: bottom right;
        background-repeat: no-repeat;
        .copy-wrapper{
            width: 525px;
        }
    }
    .branding-tile{
        padding-top: 0;
        background: #ffffff;
        background: -moz-linear-gradient(top, #f2f2f2 0%, #f2f2f2 46%, #e6e6e6 47%, #ffffff 83%, #f2f2f2 100%);
        background: -webkit-linear-gradient(top, #f2f2f2 0%,#f2f2f2 46%,#e6e6e6 47%,#ffffff 83%,#f2f2f2 100%);
        background: linear-gradient(to bottom, #f2f2f2 0%,#f2f2f2 46%,#e6e6e6 47%,#ffffff 83%,#f2f2f2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=0 );
        margin-bottom: 7em;
        .container{
            position: relative;
        }
    }
    .ribbon{
        background: $australiandream;
    }
    .ribbon-start{
        display: block;
        position: relative;
        margin-top: 4.5em;
        min-height: 300px;
        @extend .ribbon;
        @include angle(before, true, 4deg);
        padding-bottom: 3em;
        .container{
            margin-top: -4.5em;
        }
    }
    .ribbon-end{
        display: block;
        position: relative;
        margin-bottom: 4.5em;
        padding-top: 0;
        padding-bottom: 4em;
        min-height: 250px;
        @extend .ribbon;
        @include angle(after, false, 4deg);
        .container{
            position: relative;
        }
    }
    .work-tile{
        padding: 3em 2em;
        p{
            color: white;
        }
    }
    .results-tile{
        text-align: center;
        padding: 5em 2em;
        padding-top: 7em;
        .summary{
            margin-bottom: 4em;
        }
    }
    .keypoints{
        .keypoint{
            padding: 0 1.5em;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
        }
        span{
            font-size: .8em;
            color: lighten(black, 40%);
            font-weight: 300;
            &.large-figure{
                font-family: $oswald;
                font-size: 3.5em;
                text-align: center;
                display: block;
                font-weight: 300;
                margin-bottom: .25em;
                color: $australiandream;
            }
        }
    }
    .casestudies-footer{
        text-align: center;
        background-image: url('/img/shoot-image.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        padding: 7em 1em;
        position: relative;
        .ghost-btn{
            z-index: 60;
        }
    }
    @media screen and (max-width: 1100px) {
        .work-tile{
            padding: 3em 2em;
            &.ribbon-start{
                padding-bottom: 1em;
            }
        }
    }
    @media screen and (max-width: 925px) {
        .info-tile{
            background-size: 40%;
            .copy-wrapper{
                width: auto;
                padding-right: 45%;
            }
        }
    }
    @media screen and (max-width: 800px) {
        .work-tile{
            [class*=span]{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .left-info{
            width: auto;
            margin-bottom: 2em;
        }
        .mock-asset{
            position: relative;
            margin: 0 auto -8em;
        }
    }
    @media screen and (max-width: 700px) {
        .branding-tile{
            margin-bottom: 0;
        }
        .left-asset{
            width: 117%;
        }
        .right-asset{
            position: relative;
            width: 100%;
            padding: 0 1em;
        }
    }
    @media screen and (max-width: 650px) {
        .intro-tile{
            padding: 2em 1em;
            .copy-wrapper{
            }
        }
        .mock-asset{
            width: 100%;
            padding: 0 1em;
        }
        .results-tile{
            padding: 0;
            .summary{
                padding: 2em 1em;
                padding-top: 0;
                margin-bottom: 0;
            }
        }
        .keypoints{
            background: white;
            .keypoint{
                width: 100%;
                padding: 1em;
                &:first-child{
                    padding: 1em;
                    padding-top: 2em;
                }
                &:last-child{
                    padding: 1em;
                    padding-bottom: 2em;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .intro-tile{
            .copy-wrapper{
                // text-align: center;
                width: auto;
            }
        }
        .branding-tile{
            padding: 2em 1em;
        }
        .info-tile{
            background: white;
            padding: 2em 1em;
            padding-top: 4em;
            .copy-wrapper{
                // text-align: center;
                padding: 0;
            }
        }
    }
}
