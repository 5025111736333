#casestudies{
    .casestudies-banner{
        text-align: center;
        padding: 1em;
        background: $dark;
        display: none;
    }
    #tile-casestudies-list{
        border: 1px solid darken(white, 5%);
        #bob-evans {
            p { color:#000; }
            .ghost-btn { color:#000; border-color:#000; }
        }
        .casestudy{
            padding: 6em 2em;
            background-color: lighten(black, 20%);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size:cover;
            border: 1px solid darken(white, 5%);
            color: white;
            display: inline-block;
            width: 100%;
            /*float: left;*/

            h2{
                margin-bottom: .5em;
                font-size: 1.25em;
                font-family: $oswald;
                color: white;
            }
            p{
                line-height: 1.5em;
                font-size: .85em;
                letter-spacing: 0.02em;
                font-family: $ubuntu;
                color: white;
            }
            .container .row {
                display:flex;
                align-items: center;
                justify-content: center;
            }
            .client-logo:after {
                display:block;
                content:'';
                background-color: transparent;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                padding-bottom:50%;
            }
            .client-logo{
                &.australian-dream{
                    &:after{
                        background-image: url('/img/casestudies/banners/australian-dream-logo.png');
                    }
                }
                &.bob-evans{
                    &:after{
                        background-image: url('/img/casestudies/banners/bobevans.png');
                    }
                }
                &.tricalm{
                    &:after{
                        background-image: url('/img/casestudies/banners/tricalm-logo.png');
                    }
                }
                &.smart-mouth{
                    &:after{
                        background-image: url('/img/casestudies/banners/smart-mouth-logo.png');
                    }
                }
                &.willow-curve{
                    &:after{
                        background-image: url('/img/casestudies/banners/willow-curve-logo.png');
                    }
                }
                &.nauzene{
                    &:after{
                        background-image: url('/img/casestudies/banners/nauzene-logo.png');
                    }
                }
                &.fungicure{
                    &:after{
                        background-image: url('/img/casestudies/banners/fungicure-logo.png');
                    }
                }
            }
        }
        .casestudy-abstract{
            margin-left:3.5em;
            .ghost-btn{
                &:hover{
                    &.australian-dream{
                        color: $australiandream;
                    }
                    &.willow-curve{
                        color: $willowcurve;
                    }
                    &.smart-mouth{
                        color: $smartmouth;
                    }
                    &.tricalm{
                        color: $tricalm;
                    }
                }
            }
        }
        .casestudy-info{
            padding-right: 4em;
        }
    }
    @media screen and (max-width: 700px) {
        #tile-casestudies-list{
            .casestudy{
                padding: 3em;
                .client-logo{
                    width: 100%;
                    margin-bottom:1em;
                }
                .container .row {
                    display:block;
                }
                .casestudy-abstract{
                    width: 100%;
                    margin-left:0;
                    text-align:center;
                }
            }
        }
    }
}
