body{
    font-size: 1.25em;
    font-family: $ubuntu;
	letter-spacing: 0.02em;
	background: lighten(black, 10%);
	// overflow: hidden;
}

a{
    text-decoration: none;
    @extend .ease;
    &:hover{
        @extend .ease;
    }
}
#modal-window{
	.video-wrapper {
        position:relative;
        height:0;
        padding-bottom:56.25%;
        iframe {
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
        }
    }
}
// A global hide feature.  Just add the class hide.
.hide {
    display: none;
}
.lightbox{
	display: none;
	text-align: center;
	a{
		font-family: $oswald;
		color: black;
		font-size: 90%;
		display: block;
		margin: 4em 1em;
		.ghost-btn{
			color: black;
			border-color: black;
			font-size: 1em;
			margin: 0 .25em;
			padding: .25em .5em;
		}
	}
	h2{
		text-align: left;
		margin-top: .35em;
		margin-left: .5em;
		margin-bottom: .45em;
		font-size: 97%;
	}
	.items{
		y-overflow: scroll;
	}
	.item{
		width: 100%;
		margin-bottom: .15em;
		max-width: 80hw;
		img{
			width: 100%;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.featherlight{
	padding: .25em;
}
.full-img{
	width: 100%;
}
.intro-copy{
    max-width: 850px;
}
.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
strong{
	font-weight: 900;
}
.article-header{
	img{
		width: 100%;
	}
}
.locations-list{
	font-size: .6em;
	margin-top: 1em;
	color: darken(white, 30%);
	display: block;
	li{
		margin-left: .25em;
		margin-right: .25em;
		list-style-type: disc;
		color: darken(white, 30%);
		text-transform: uppercase;
		display: inline-block;
		&:after{
			padding-left: .75em;
			content: "•";
		}
		&:first-child{
			list-style-type: none;
			margin-left: 0;
		}
		&:last-child{
			margin-right: 0;
			&:after{
				padding-left: 0;
				content: "";
			}
		}
	}
}
.shadow{
	-webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
	-moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
	box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
}
.text-shadow{
	text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.71);
}
blockquote{
	padding: 1.5em;
	font-size: .9em;
	padding-left: 2em;
	border: 1px solid darken(white, 7%);
	border-left: 5px solid $green;
	background: rgba(255, 255, 255, .3);
	margin-bottom: 2em;
	@extend .shadow;
	p{
		line-height: 2em;
		font-weight: 300;
		color: darken(white, 60%);
	}
}
.site-content {
  flex: 1;
}
.lity-wrap{
	padding: 2em;
}
.embeddedContent {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0; 
	overflow:hidden;
	iframe, object, embed {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
	}
}
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}
.container{
	p{
		margin-bottom: 1.5em;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
h2{
	font-size: 1.25em;
	margin-bottom: .5em;
	font-weight: 500;
	line-height: 1.25em;
	font-family: $oswald;
	color: lighten(black, 30%);
}
.embed-container {
	iframe, object, embed{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.submit-btn{
	font-size: .85em;
	border: 0;
	font-weight: 700;
	text-transform: uppercase;
	padding: .75em 1.5em;
	outline: 0;
	background: $green;
	color: white;
	cursor: pointer;
	@extend .ease;
	&:hover{
		background: darken($green, 5%);
		@extend .ease;
	}
}
.ghost-btn{
	border: 2px solid white;
	padding: .5em .75em;
	color: white;
	font-size: .8em;
	display: inline-block;
	font-family: $oswald;
	cursor: pointer !important;
	&.inverted{
		border-color: lighten(black, 20%);
		color: lighten(black, 20%);
		&:hover{
			background: lighten(black, 20%);
			color: white;
		}
	}
	&:hover{
		background: white;
		color: $dark;
	}
}
#breadcrumb{
	font-size: .8em;
	padding: 2em 1em;
	background: darken(white, 10%);
	font-size: .8em;
	ul{
		&:before{
			@extend .fa-custom;
			content:'\f041';
			color: lighten(black, 30%);
		}
		li{
			display: inline-block;
			color: lighten(black, 50%);
			font-weight: 300;
			margin-right: .25em;
			a{
				color: lighten(black, 30%);
				font-weight: 700;
				&:hover{
					text-decoration: underline;
				}
			}
			&:last-child{
				margin-right: 0;
				&:after{
					content: '';
					padding-left: 0;
				}
			}
			&:after{
				padding-left: .5em;
				content: "/";
				display: inline-block;
			}
		}
	}
}
address{
	margin: 1em 0;
	font-size: .8em;
	font-weight: 300;
	a{
		color: darken(white, 40%);
		&:hover{
			color: white;
		}
	}
	br{
		line-height: 1.35em;
	}
}
.fade-text{
	border-bottom: 1px solid darken(white, 78%);
	-webkit-box-shadow: inset 0px -15px 9px -5px rgba(0,0,0,0.15);
	-moz-box-shadow: inset 0px -15px 9px -5px rgba(0,0,0,0.15);
	box-shadow: inset 0px -15px 9px -5px rgba(0,0,0,0.15);
	&:after{
		position: absolute;
		display: block;
		content: 'SCROLL';
		bottom: 1.5em;
		color: darken(white, 60%);
		font-size: .8em;
		right: 2.5em;
	}
}
[id$="-header"]{
	background: darken(white, 0%);
}
#face-switcher-bar{
	padding: .5em;
	text-align: center;
	justify-content: center;
	background: white;
	border-top: 1px solid lighten(black, 90%);
	flex-direction: row;
	display: inline-flex;
	width: 100%;
	.switch-wrapper{
		text-align: center;
		justify-content: center;
		flex-direction: row;
		display: inline-flex;
	}
	h3{
		display: inline-flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding-right: .5em;
		font-size: 1em;
		font-weight: 100;
		font-family: $oswald;
		color: lighten(black, 20%);
		&.hyde{
			padding-left: .5em;
			color: $green;
			padding-right: 0;
		}
	}
	label{
		margin-bottom: 0;
	}
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    input{
        /* Hide default HTML checkbox */
        display: none;
    }
}
/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: lighten(black, 20%);
	-webkit-transition: .4s;
	transition: .4s;
	&.round{
		border-radius: 34px;
		&:before{
			border-radius: 50%;
		}
	}
	&:before{
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}
}
input{
	&:checked{
		&+ .slider{
			background-color: $green;
			&:before{
				-webkit-transform: translateX(26px);
				-ms-transform: translateX(26px);
				transform: translateX(26px);
			}
		}
	}
	&:focus{
		&+ .slider{
			box-shadow: 0 0 1px $green;
		}
	}
}
.social-icons{
	display: block;
	li{
		&.mobile{
			display: none;
		}
		margin-right: .5em;
		display: inline-block;
		justify-content: center;
		flex-direction: column;
		&:last-child{
			margin-right: 0;
		}

		&.fb{
			a{
				background: $fb;
				&:hover{
					background: darken($fb, 10%);
					color: darken(white, 10%);
				}
			}
		}
		&.li{
			a{
				background: $li;
				&:hover{
					background: darken($li, 10%);
					color: darken(white, 10%);
				}
			}
		}
		&.tw{
			a{
				background: $tw;
				&:hover{
					background: darken($tw, 10%);
					color: darken(white, 10%);
				}
			}
		}
		&.gp{
			a{
				background: $gp;
				&:hover{
					background: darken($gp, 10%);
					color: darken(white, 10%);
				}
			}
		}
		a{
			border-radius: 25px;
			justify-content: center;
			text-align: center;
			color: white;
			font-size: .9em;
			padding: .5em;
			height: 35px;
			width: 35px;
			display: flex;
			position: relative;
		}
	}
}
#site-wrapper{
	position: relative;
	width: 100%;
	background: darken(white, 5%);
}
.parallax-mirror {
    z-index: 1 !important;
}
#filter{
	padding: 1em;
	background: white;
	a{
		float: right;
	}
	ul{
		float: left;
		padding-top: .5em;
		padding-left: .5em;
		li{
			display: inline-block;
			margin-right: 1.5em;
			font-weight: 500;
			font-size: .8em;
			font-family: $oswald;
			a{
				color: lighten(black, 15%);
				&#reset{
					-ms-transform: rotate(0deg);
					-webkit-transform: rotate(0deg);
					transform: rotate(0deg);
					@extend .ease;
					&:hover{
						@extend .ease;
						-ms-transform: rotate(180deg);
						-webkit-transform: rotate(180deg);
						transform: rotate(180deg);
					}
				}
				&.on{
					color: lighten(black, 15%);
				}
				&.off{
					color: lighten(black, 85%);
					&:hover{
						color: lighten(black, 75%);
					}
				}
				&:hover{
					color: black;
				}
			}
			&:last-child{
				margin-right: 0;
			}
		}
	}
}
#site-canvas{
	min-height: 100vh;
	padding-top: 79px;
}
#site-canvas, #mobile-nav, header{
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	-webkit-transform: translateX(0);
	transform: translateX(0);

	-moz-transform: translateZ(0) translateX(0);
	-ms-transform: translateZ(0) translateX(0);
	-o-transform: translateZ(0) translateX(0);
	-webkit-transform: translateZ(0) translateX(0);
	transform: translateZ(0) translateX(0);

	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	-moz-transition: 300ms ease all;
	-ms-transition: 300ms ease all;
	-o-transition: 300ms ease all;
	-webkit-transition: 300ms ease all;
	transition: 300ms ease all;

	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	#menu-overlay{
		z-index: 4;
		width: 100%;
		height: 100%;
		position: absolute;
		opacity: .5;
		background: black;
		display: none;
	}
}
.show-nav #site-canvas, .show-nav #mobile-nav, .show-nav header{
    -moz-transform: translateX(-250px);
    -ms-transform: translateX(-250px);
    -o-transform: translateX(-250px);
    -webkit-transform: translateX(-250px);
    transform: translateX(-250px);

    -moz-transform: translateZ(0) translateX(-250px);
    -ms-transform: translateZ(0) translateX(-250px);
    -o-transform: translateZ(0) translateX(-250px);
    -webkit-transform: translateZ(0) translateX(-250px);
    transform: translateZ(0) translateX(-250px);

    -moz-transform: translate3d(-250px, 0, 0);
    -ms-transform: translate3d(-250px, 0, 0);
    -o-transform: translate3d(-250px, 0, 0);
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
}
#mobile-nav{
	width: 250px;
	right: -250px;
	position: fixed;
	height: 100%;
	top: 0;
	z-index: 998;
	background: lighten(black, 7%);
	padding: 1.5em;
	box-shadow: inset 8px 0px 12px -6px #000;
	.social-icons{
		margin-top: .5em;
		li{
			a{
				font-size: .8em;
				&:hover{
					color: white;
				}
			}
		}
	}
	nav{
		border-bottom: 1px solid darken(white, 80%);
		margin-bottom: 1em;
		letter-spacing: 0.02em;
	}
	nav{
		ul{
			li{
				margin-bottom: .5em;
				display: block;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 1.15em;
				padding-bottom: .5em;
				a{
					color: white;
					&:hover{
						color: $green;
					}
				}
			}
			.primary-nav{
				display: none;
			}
			#nav-menu-btn{
				display: none;
			}
			.sub-nav li{
				font-weight: 300;
				font-family: $ubuntu;
				font-size: .9em;
				text-transform: uppercase;
			}
		}
	}
}
.error-list{
	color: red;
	margin-bottom: .5em;
	li{
		list-style-position: inside;
		list-style-type: disc;
		margin-bottom: .5em;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
#alert-bar{
	width: 100%;
	padding: .5em;
	text-align: center;
	color: white;
	font-size: 1.15em;
	background: $green;
}
.mini-block{
	padding: 2em;
}
.block{
	padding: 4em;
}
label{
	display: block;
	margin-bottom: .25em;
	color: lighten(black, 50%);
	font-size: .8em;
}
textarea{
	max-width: 100%;
	min-width: 100%;
}
.input{
	width: 100%;
	padding: .35em;
	background: darken(white, 2%);
	border: 1px solid darken(white, 20%);
	font-size: 1.15em;
	color: lighten(black, 50%);
	outline: 0;
	&:focus{
		@extend .ease;
		border-color: $green;
	}
}
.input-row{
	margin-bottom: 1em;
	&:last-child{
		margin-bottom: 0;
	}
	[class^='span']{
		&:first-child{
			padding-right: .5em;
		}
		&:last-child{
			padding-left: .5em;
		}
	}
}
#nav-menu-btn{
	z-index: 9999;
	position: relative;
	font-size: 1.25em;
}
#tile-agency-contact{
	background: $green url('/img/sb-2.png');
	padding: 1em 2em;
	h3{
		display: inline-block;
		font-size: 1.5em;
		font-family: $oswald;
		font-weight: 500;
		color: white;
	}
	.agency-contact{
		float: right;
		display: inline-block;
		font-size: 1em;
		font-weight: 300;
		padding-top: .25em;
		a{
			margin-right: 1em;
			padding-right: 1em;
			color: lighten(black, 95%);
			&:last-child{
				margin-right: 0;
				padding-right: 0;
				border-right: 0;
			}
			&:hover{
				color: lighten(black, 20%);
			}
		}
		i{
			padding-right: .35em;
		}
	}
}

#main-nav{
	text-align: right;
	font-size: 1.1em;
	.sub-nav{
		display: none;
	}
	ul{
		padding-top: .15em;
		padding-bottom: .85em;
		li{
			display: inline-block;
			margin-right: 1em;
			font-family: $oswald;
			text-transform: uppercase;
			font-size: .8em;
			font-weight: 500;
			.close-btn{
				display: none;
			}
			a{
				color: white;
				&:hover{
					color: $green;
				}
				&.active{
					border-bottom: 2px solid $green;
				}
			}
			&:last-child{
				margin-right: 0;
				&:after{
					content: "";
				}
			}
			&:after{
				padding-left: 1em;
				content: "•";
			}
		}
	}
}
header{
    background: lighten(black, 10%);
    padding: .4em;
	padding-right: 0;
	padding-top: 1.25em;
    color: white;
	z-index: 50 !important;
    position: fixed;
    top: 0;
    width: 100%;
	#logo-wrapper{
		position: relative;
		.logo{
			position: absolute;
			height: 92px;
			top: -23px;
			width: 330px;
			left: -.4em;
			cursor: pointer;
			@extend .ease;
			.sprite-box{
				position: relative;
				width: 100%;
				height: 100%;
				.jekyll-logo, .hyde-logo{
					@extend .ease;
					width: 100%;
					height: 100%;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					position: absolute;
					background: url('/img/logo-face-sprite.png') no-repeat;
				}
				.jekyll-logo{
					z-index: 89;
					background-position: 0 0;
				}
				.hyde-logo{
					z-index: 90;
					opacity: 0;
					background-position: 0 -92px;
				}
				&:hover{
					@extend .ease;
					.jekyll-logo{
					}
					.hyde-logo{
						opacity: 1;
					}
				}
			}
		}
	}
}
footer{
    background: lighten(black, 10%);
    padding: 2em;
	box-shadow: 0px 500px 0px 500px lighten(black, 10%);
	.footer-info{
		text-align: right;
		float: right;
		.social-icons{
			// float: right;
		}
	}
    nav{
        ul{
			column-count: 2;
            li{
                font-size: .7em;
                font-weight: 300;
                text-transform: uppercase;
                margin-bottom: 1em;
                &:last-child{
                    margin-bottom: 0;
                }
                a{
					&#nav-menu-btn{
						display: none;
					}
                    color: darken(white, 40%);
					&:hover{
						color: white;
					}
                }
            }
        }
    }
	.legal-footer{
		border-top: 1px solid lighten(black, 20%);
		margin-top: 2em;
		font-size: .7em;
		color: lighten(black, 30%);
		font-weight: 300;
		padding-top: 1em;
		ul{
			li{
				display: inline-block;
				margin-right: 1em;
				a{
					color: lighten(black, 40%);
					&:hover{
						color: white;
					}
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}
.inline-link{
	color: $green;
	font-weight: 700;
	border-bottom: 2px solid $green;
}
input, textarea{
	background: white;
	border: 1px solid darken(white, 20%);
	border-radius: 3px;
	padding: .5em;
	font-size: 1.05em;
	width: 100%;
	color: lighten(black, 30%);
}
textarea{
	font-size: 1em;
}
.field{
	display: inline-block;
	padding-left: .25em;
	padding-right: .25em;
	margin-bottom: .5em;
	&:first-child{
		padding-left: 0;
	}
	&:last-child{
		padding-right: 0;
	}
	&:last-child{
		padding-bottom: 0;
	}
}
h1{
	font-family: $oswald;
	text-transform: uppercase;
	font-size: 1.5em;
	margin-bottom: .5em;
	color: $green;
	font-weight: 500;
	display: inline-block;
	line-height: 1.15em;
}
p{
	font-size: 1em;
	line-height: 160%;
	font-family: $quattrocento;
	color: lighten(black, 30%);
	margin-bottom: 1em;
	font-weight: 300;
	&:last-child{
		margin-bottom: 0;
	}
}
@media screen and (max-width: 900px){
	#main-nav{
		.primary-nav{
			li{
				display: none;
				&:last-child{
					display: inline-block;
				}
			}
		}
	}
	#mobile-nav{
		nav{
			ul{
				.primary-nav{
					display: block;
					li{
						&:last-child{
							display: none;
						}
					}
				}
				.sub-nav{
					margin-top: 0;
				}
			}
		}
	}
}
@media screen and (max-width: 800px) {
	#main-nav{
		.primary-nav{
			li{
				display: none;
				&:last-child{
					display: inline-block;
				}
			}
		}
	}
	#tile-agency-contact{
		h3{
			display: block;
			text-align: center;
			font-size: 1em;
			margin-bottom: .5em;
		}
		.agency-contact{
			float: none;
			text-align: center;
			display: block;
			a{
				display: block;
				border: 0;
				margin-bottom: .5em;
				margin-right: 0;
				padding-right: 0;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
}
@media screen and (max-width: 600px) {
	blockquote{
		padding: 1em;
	}
	.block{
		padding: 2em;
	}
	p{
		font-size: .9em;
	}
	#breadcrumb{
		font-size: .7em;
		ul{
			li{
				line-height: 1.25em;
			}
		}
	}
	footer{
		.legal-footer{
			text-align: center;
			ul{
				li{
					display: block;
					margin-right: 0;
					margin-bottom: .5em;
				}
			}
		}
	}
	.social-icons{
		.social-icon{
			&.desktop{
				display: inline-block;
			}
			&.mobile{
				display: none;
			}
		}
	}
}
@media screen and (max-width: 550px) {
	footer{
		.footer-nav, .footer-info{
			width: 100%;
			text-align: center;
		}
		.footer-nav{
			margin-bottom: 2em;
		}
		.footer-info{
			.social-icons{
				justify-content: center;
				width: 100%;
				margin: 0 auto;
			}
		}
	}
}
@media screen and (max-width: 450px) {
	header{
		#logo-wrapper{
			.logo{
				top: -15px;
				width: 276px;
				height: 77px;
				.sprite-box{
					.jekyll-logo, .hyde-logo{
						background-size: 100%;
					}
					.hyde-logo{
						background-position: 0 -77px;
					}
				}
			}
		}
	}
}
