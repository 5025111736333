#careers{
    .video-wrapper {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    #tile-careers-posting-list{
        // margin: .25%;
        ul{
            display: flex;
            align-content: baseline;
            flex-wrap: wrap;
            border: 1px solid darken(white, 5%);
            li{
                // &:nth-child(odd){
                //     background: lighten(black, 30%);
                // }
                background: url('/img/sb-3.png');
                border: 1px solid darken(white, 5%);
                text-align: center;
                position: relative;
                // width: 32.8333333%;
                // margin: .25%;
                background: lighten(black, 20%);
                width: 33.333333%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                font-weight: 300;
                letter-spacing: 0.02em;
                @extend .ease;
                h2{
                    line-height: 1.5em;
                }
                &:hover{
                    // background: url('/img/sb-2.png');
                    background: lighten(black, 15%);
                    color: white;
                    @extend .ease;
                }
                a{
                    padding: 4.5em 4em;
                    color: white;
                }
            }
        }

         .none-to-show {
             text-align: center;
             padding: 4em;
             font-size: 26px;
         }
    }
    @media screen and (max-width: 1000px) {
        #tile-careers-posting-list{
            ul{
                li{
                    // width: 49.75%;
                    width: 50%;
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        #tile-careers-posting-list{
            ul{
                li{
                    // width: 99.75%;
                    width: 100%;
                }
            }

            .none-to-show {
                font-size: 20px;
            }
        }
    }
}
