#home{
    #tile-home-hero{
        background: url('/img/hero-home.png');
        background-size: 100%;
        padding: 6em 0;
        img{
            margin: 0 auto;
            display: block;
        }
    }
    #tile-client-banner{
        background: lighten(black, 15%);
        padding: 1em;
        .light-slider{
            li{
                background: lighten(black, 15%);
                padding: 0 .75em;
            }
        }
    }
    #tile-home-blocks{
        background: darken(white, 1%);
        .large-block{
            img{
                width: 100%;
            }
            .block-cover{
                background: rgba(62,179,79,.8);
            }
        }
        .mini-blocks{
            .large-block{
                background: url('/img/bobevans-tile.jpg');
                position: relative;
                background-position:50% 0;
                background-size:cover;
                background-repeat: no-repeat;
                .block-content{
                    padding: 10.5em 0;
                    z-index: 5;
                    .ghost-btn{
                        &:hover{
                            color: black;
                        }
                    }
                }
                .block-cover{
                    background: rgba(0,0,0,.4);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
            [class*="block-"]{
                text-align: center;
                position: relative;
                .block-content{
                    padding: 5.1em 0;
                    z-index: 5;
                }
                .block-cover{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                .ghost-btn{
                    display: inline-block;
                    &:hover{
                        color: $green;
                        background: white;
                        border-color: white;
                    }
                }
                &[class*="1"]{
                    background: url('/img/nicobloc-tile-1.jpg');
                    background-size: cover;
                    .block-cover{
                        background: rgba(62,179,79,.8);
                    }
                }
                &[class*="2"]{
                    background: url('/img/nauzene-tile-1.jpg');
                    background-size: cover;
                    .ghost-btn{
                        &:hover{
                            color: black;
                        }
                    }
                    .block-cover{
                        background: rgba(0,0,0,.7);
                    }
                }
                &[class*="3"]{
                    background: url('/img/jekyllhyde-tile-1.jpg');
                    background-size: cover;
                    .block-cover{
                        background: rgba(62,179,79,.8);
                    }
                }
                &[class*="4"]{
                    background: url('/img/jh-tile-1.jpg');
                    background-size: cover;
                    .ghost-btn{
                        &:hover{
                            color: black;
                        }
                    }
                    .block-cover{
                        background: rgba(0,0,0,.75);
                    }
                }
            }
        }
        .block-main-copy{
            padding: 3em;
            overflow-y: visible;
            background: darken(white, 1%);
            h1{
                color: lighten(black, 20%);
                font-weight: 900;
            }
            p{
                font-size: .95em;
            }
            h2{
                color: $green;
                font-family: $oswald;
                font-size: 1.15em;
                text-transform: uppercase;
            }
        }
    }
    #tile-leadership-preview{
        ul{
            &#leadership-slider-desktop{

            }
            &#leadership-slider-mobile{
                display: none;
                li{
                    span{
                        font-size: 1em;
                    }
                }
            }
            li{
                background-color: lighten(black, 10%);
                background-position: left;
                background-size: 100%;
                background-repeat: no-repeat;
                margin-right: 0;
                position: relative;
                overflow: hidden;
                background-size: 110%;
                .employee-image{
                    position: absolute;
                    left: 1em;
                    width: 10em;
                    img{
                        width: 100%;
                    }
                }
                br{
                    line-height: 1.25em;
                }
                h4{
                    font-size: 1.5em;
                    font-family: $oswald;
                    text-transform: uppercase;
                    color: white;
                    margin-bottom: .25em;
                    display: block;
                }
                a{
                    display: block;
                    color: $green;
                    font-size: .9em;
                }
                .leader-info{
                    margin-left: 40%;
                    min-height: 138px;
                    background: rgba(0,0,0,.7);
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .info-wrapper{
                        padding: .5em;
                    }
                    h4{
                        line-height: 1.25em;
                    }
                    span{
                        line-height: 1.25em;
                        color: $green;
                        display: block;
                        font-size: .8em;
                        font-family: $ubuntu;
                        font-style: italic;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    @media screen and (max-width:1500px){
        #tile-home-blocks .mini-blocks [class*="block-"] .block-content {
            padding:7em 0;
        }
    }
    @media screen and (max-width: 1200px) {
        #tile-leadership-preview{
            ul{
                li{
                    font-size: 90%;
                    .employee-image{
                        width: 10em;
                    }
                    .leader-info{
                        margin-left: 50%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1150px) {
    	#tile-home-blocks{
            .block-main-copy, .mini-blocks{
                width: 100%;
            }
            .mini-blocks{
                [class*="mini-block-"]{
                    .block-content{
                        padding: 8em 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        #tile-leadership-preview{
            ul{
                li{
                    background-position-y: 0;
                    font-size: 80%;
                    background-size: 1000px;
                    .employee-image{
                        left: 2em;
                        top: .5em;
                        width: 11em;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        #tile-home-hero{
            padding: 6em 3em;
            img{
                width: 100%;
            }
        }
        #tile-client-banner{
        }
        #tile-leadership-preview{
            ul{
                li{
                    .employee-image{
                        left: 10%;
                        width: 14em;
                    }
                }
            }
        }
        #tile-home-blocks{
            .mini-blocks{
                [class*="mini-block-"]{
                    .block-content{
                        padding: 5em 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        #tile-home-blocks{
            .block-main-copy{
                padding: 1.5em;
                padding-top: 2em;
            }
        }
        #tile-leadership-preview{
            ul{
                li{
                    font-size: 80%;
                }
            }
        }
    }
    @media screen and (max-width: 550px) {
        #tile-client-banner{
            margin-top: 0;
        }
        #tile-leadership-preview{
            ul{
                li{
                    .employee-image{
                        left: .5em;
                    }
                }
            }
        }
        #tile-home-blocks{
            .mini-blocks{
                .large-block{
                    .block-content{
                        padding: 4em 0;
                    }
                }
            }
            [class*='mini-block-']{
                width: 100%;
                .block-content{
                    padding: 7em 0;
                }
            }
        }
    }

}
#home{
    #tile-home-hero{
        background: url('/img/hero-home.png');
        background-size: 100%;
        padding: 6em 0;
        img{
            margin: 0 auto;
            display: block;
        }
    }
    #tile-client-banner{
        background: lighten(black, 15%);
        padding: 1em;
        .light-slider{
            li{
                background: lighten(black, 15%);
                padding: 0 .75em;
                i{
                    display: inline-block;
                }
            }
        }
    }
    #tile-home-blocks{
        background: darken(white, 1%);
        .large-block{
            img{
                width: 100%;
            }
            .block-cover{
                background: rgba(62,179,79,.8);
            }
        }
        .mini-blocks{
            .large-block{
                background: url('/img/bobevans-tile.jpg');
                position: relative;
                background-position:50% 0;
                background-size:cover;
                background-repeat: no-repeat;
                .block-content{
                    padding: 10.5em 0;
                    z-index: 5;
                    .ghost-btn{
                        &:hover{
                            color: black;
                        }
                    }
                }
                .block-cover{
                    background: rgba(0,0,0,.4);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
            [class*="block-"]{
                text-align: center;
                position: relative;
                .block-content{
                    padding: 5.1em 0;
                    z-index: 5;
                }
                .block-cover{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                .ghost-btn{
                    display: inline-block;
                    &:hover{
                        color: $green;
                        background: white;
                        border-color: white;
                    }
                }
                &[class*="1"]{
                    background: url('/img/nicobloc-tile-1.jpg');
                    background-size: cover;
                    .block-cover{
                        background: rgba(62,179,79,.8);
                    }
                }
                &[class*="2"]{
                    background: url('/img/nauzene-tile-1.jpg');
                    background-size: cover;
                    .ghost-btn{
                        &:hover{
                            color: black;
                        }
                    }
                    .block-cover{
                        background: rgba(0,0,0,.7);
                    }
                }
                &[class*="3"]{
                    background: url('/img/jekyllhyde-tile-1.jpg');
                    background-size: cover;
                    .block-cover{
                        background: rgba(62,179,79,.8);
                    }
                }
                &[class*="4"]{
                    background: url('/img/jh-tile-1.jpg');
                    background-size: cover;
                    .ghost-btn{
                        &:hover{
                            color: black;
                        }
                    }
                    .block-cover{
                        background: rgba(0,0,0,.75);
                    }
                }
            }
        }
        .block-main-copy{
            padding: 3em;
            overflow-y: visible;
            background: darken(white, 1%);
            h1{
                color: lighten(black, 20%);
                font-weight: 900;
            }
            p{
                font-size: .95em;
            }
            h2{
                color: $green;
                font-family: $oswald;
                font-size: 1.15em;
                text-transform: uppercase;
            }
        }
    }
    #tile-leadership-preview{
        ul{
            &#leadership-slider-desktop{

            }
            &#leadership-slider-mobile{
                display: none;
                li{
                    span{
                        font-size: 1em;
                    }
                }
            }
            li{
                background-color: lighten(black, 10%);
                background-position: left;
                background-size: 100%;
                background-repeat: no-repeat;
                margin-right: 0;
                position: relative;
                overflow: hidden;
                background-size: 110%;
                .employee-image{
                    position: absolute;
                    left: 1em;
                    width: 10em;
                    img{
                        width: 100%;
                    }
                }
                br{
                    line-height: 1.25em;
                }
                h4{
                    font-size: 1.5em;
                    font-family: $oswald;
                    text-transform: uppercase;
                    color: white;
                    margin-bottom: .25em;
                    display: block;
                }
                a{
                    display: block;
                    color: $green;
                    font-size: .9em;
                }
                .leader-info{
                    margin-left: 40%;
                    min-height: 138px;
                    background: rgba(0,0,0,.7);
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .info-wrapper{
                        padding: .5em;
                    }
                    h4{
                        line-height: 1.25em;
                    }
                    span{
                        line-height: 1.25em;
                        color: $green;
                        display: block;
                        font-size: .8em;
                        font-family: $ubuntu;
                        font-style: italic;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    @media screen and (max-width:1500px){
        #tile-home-blocks .mini-blocks [class*="block-"] .block-content {
            padding:7em 0;
        }
    }
    @media screen and (max-width: 1200px) {
        #tile-leadership-preview{
            ul{
                li{
                    font-size: 90%;
                    .employee-image{
                        width: 10em;
                    }
                    .leader-info{
                        margin-left: 50%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1150px) {
    	#tile-home-blocks{
            .block-main-copy, .mini-blocks{
                width: 100%;
            }
            .mini-blocks{
                [class*="mini-block-"]{
                    .block-content{
                        padding: 8em 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        #tile-leadership-preview{
            ul{
                li{
                    background-position-y: 0;
                    font-size: 80%;
                    background-size: 1000px;
                    .employee-image{
                        left: 2em;
                        top: .5em;
                        width: 11em;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        #tile-home-hero{
            padding: 6em 3em;
            img{
                width: 100%;
            }
        }
        #tile-client-banner{
        }
        #tile-leadership-preview{
            ul{
                li{
                    .employee-image{
                        left: 10%;
                        width: 14em;
                    }
                }
            }
        }
        #tile-home-blocks{
            .mini-blocks{
                [class*="mini-block-"]{
                    .block-content{
                        padding: 5em 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        #tile-home-blocks{
            .block-main-copy{
                padding: 1.5em;
                padding-top: 2em;
            }
        }
        #tile-leadership-preview{
            ul{
                li{
                    font-size: 80%;
                }
            }
        }
    }
    @media screen and (max-width: 550px) {
        #tile-client-banner{
            margin-top: 0;
        }
        #tile-leadership-preview{
            ul{
                li{
                    .employee-image{
                        left: .5em;
                    }
                }
            }
        }
        #tile-home-blocks{
            .mini-blocks{
                .large-block{
                    .block-content{
                        padding: 4em 0;
                    }
                }
            }
            [class*='mini-block-']{
                width: 100%;
                .block-content{
                    padding: 7em 0;
                }
            }
        }
    }

}
