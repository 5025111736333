#learning{
    #tile-learning-list{
        // margin: .25%;
        ul{
            display: flex;
            align-content: baseline;
            flex-wrap: wrap;
            border: 1px solid darken(white, 5%);
            li{
                // &:nth-child(odd){
                //     background: lighten(black, 30%);
                // }
                background: lighten(black, 20%);
                background-position: center;
                background-size: 100%;
                text-align: center;
                position: relative;
                width: 33.333333%;
                // width: 32.8333333%;
                // margin: .25%;
                border: 1px solid darken(white, 5%);
                display: flex;
                position: relative;
                cursor: pointer;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                font-weight: 300;
                letter-spacing: 0.02em;
                z-index: 48;
                @extend .ease;
                h3{
                    position: relative;
                    line-height: 1.5em;
                    z-index: 50;
                    @extend .text-shadow;
                }
                &:hover{
                    color: white;
                    @extend .ease;
                    .learning-cover{
                        opacity: 1;
                        @extend .ease;
                    }
                }
                a{
                    padding: 4.5em 4em;
                    color: white;
                }
                .learning-cover{
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0,0,0,.5);
                    z-index: 49;
                }
            }
        }
    }
    @media screen and (max-width: 1250px) {
        #tile-learning-list{
            ul{
                li{
                    background-size: 140%;
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        #tile-learning-list{
            ul{
                li{
                    // width: 49.5%;
                    width: 50%;
                    background-size: 100%;
                }
            }
        }
    }
    @media screen and (max-width: 850px) {
        #tile-learning-list{
            ul{
                li{
                    background-size: 140%;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        #tile-learning-list{
            .block{
                padding: 4em 0 0 0;
            }
            ul{
                li{
                    // width: 99.75%;
                    width: 100%;
                    margin: 0;
                    // margin-bottom: .25%;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
