#about-show{
    h2{
        font-style: italic;
        margin-bottom: 1em;
        color: $green;
    }
    h1{
        margin-bottom: .25em;
        color: lighten(black, 20%);
    }
    #tile-employee-header{
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        .hyde-photo{
            width: 20em;
            padding-top: 3em;
            margin-left: 1em;
            img{
                display: block;
                width: 100%;
            }
        }

        .hyde-photo{
            top: -.75em;
            left: -.25em;
            background: white;
            padding: .4em .4em 1.7em .4em;
            margin-left: 1em;
            width: 20em;
            border-radius:  1px;
            @extend .shadow;
            transform: rotate(-5deg);
            -moz-transform: rotate(-5deg);
            -webkit-transform: rotate(-5deg);
            box-shadow:0 6px 3px rgba(0,0,0,0.8);
            img{
                border: 1px solid darken(white, 5%);
                border-radius: 1px;
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 600px) {
        #tile-employee-header{
            text-align: center;
            .hyde-photo{
                width: 15em;
                margin: 0 auto;
            }
        }
    }
    @media screen and (max-width: 400px) {
        #tile-employee-header{
            .hyde-photo{
                width: 80%;
            }
        }
    }
}
