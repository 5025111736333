@viewport {
  zoom: 1.0;
  width: extend-to-zoom;
}
@-ms-viewport {
  width: extend-to-zoom;
  zoom: 1.0;
}
.container {
  /* * For IE 6/7 only * Include this rule to trigger hasLayout and contain floats. */

  *zoom: 1;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
}
.container:after {
  clear: both;
}
.container:before,
.container:after {
  content: " ";
  display: table;
}
.row {
  /* * For IE 6/7 only * Include this rule to trigger hasLayout and contain floats. */
  *zoom: 1;
}
.row:after {
  clear: both;
}
.row:before,
.row:after {
  content: " ";
  display: table;
}
*{
	box-sizing: border-box;
}
[class^="span"] {
min-height: 1px;
  float: left;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */

}
[class^="span"]:first-child {
  margin-left: 0;
}
.span1 {
  width: 8.333333333333332%;
}
.span2 {
  width: 16.666666666666664%;
}
.spanfifth{
	width: 20%;	
}
.span3 {
  width: 25%;
}
.span4 {
  width: 33.33333333333333%;
}
.span5 {
  width: 41.66666666666667%;
}
.span6 {
  width: 50%;
}
.span7 {
  width: 58.333333333333336%;
}
.span8 {
  width: 66.66666666666666%;
}
.span9 {
  width: 75%;
}
.span10 {
  width: 83.33333333333334%;
}
.span11 {
  width: 91.66666666666666%;
}
.span12 {
  width: 100%;
}
.push1 {
  margin-left: 8.333333333333332%;
}
.push2 {
  margin-left: 16.666666666666664%;
}
.pushfifth{
  margin-left: 20%;	
}
.push3 {
  margin-left: 25%;
}
.push4 {
  margin-left: 33.33333333333333%;
}
.push5 {
  margin-left: 41.66666666666667%;
}
.push6 {
  margin-left: 50%;
}
.push7 {
  margin-left: 58.333333333333336%;
}
.push8 {
  margin-left: 66.66666666666666%;
}
.push9 {
  margin-left: 75%;
}
.push10 {
  margin-left: 83.33333333333334%;
}
.push11 {
  margin-left: 91.66666666666666%;
}
.push12 {
  margin-left: 100%;
}
[class^="m-span"] {
  padding: 10px 0;
  margin-left: .5%;
  float: left;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */

}
[class^="m-span"]:last-child {
	margin-right: .5%;
}
.m-span1 {
  width: 5.46875%;
}
.m-span2 {
  width: 16.0833333%;
}
.m-span3 {
  width: 22.65625%;
}
.m-span4 {
  width: 31.25%;
}
.m-span5 {
  width: 39.84375%;
}
.m-span6 {
  width: 48.4375%;
}
.m-span7 {
  width: 57.03125%;
}
.m-span8 {
  width: 65.625%;
}
.m-span9 {
  width: 74.21875%;
}
.m-span10 {
  width: 82.8125%;
}
.m-span11 {
  width: 91.40625%;
}
.m-span12 {
  width: 100%;
}
