#dr {
    #gotobutton {
        display:block;
        position:fixed;
        bottom:1em;
        left:50%;
        transform:translateX(-50%);
        z-index:44;
        width:15em;
        max-width:90%;
        text-align:center;
        box-shadow:0 0.1em 0.2em rgba(0,0,0,0.15), 0 0.2em 0.5em rgba(0,0,0,0.15), 0 0.5em 1em rgba(0,0,0,0.15);
    }
    p { color:#222; }
    #intro {
        background:#222;
        h1 {
            color:#fff;
            margin:0.5em 0;
        }
    }
    #infoblock {
    /*
        background:#f2f2f2 url(/img/labbg.png) center center;
        background-size:cover;
    */
    }
    p {
    margin:1em 0;
    }
    .site-content {
        h2, h3, h4, h5, h6 {
            font:1.5em/1 Ubuntu,sans-serif;
            font-weight:600;
            color:#222;
        }
    }
    p a { font-weight:600; }
    .squished {
        margin:0.5em 0;
    }
    .bubble {
        background:#fff;
        padding:2.5em;
        width:65%;
        border-radius:2em;
        position:relative;
        margin-bottom:2em;
        h3 {
            color:#3eb34f;
        }
        &:after {
            width:6em;
            height:6em;
            position:absolute;
            left:100%;
            bottom:-2em;
            content:'';
            display:block;
            border-radius:0 100% 0 0;
            border:1em solid #fff;
            border-width:2em 2em 0 0;
        }
        &.right {
            margin:0 0 2em auto;
            &:after {
                left:auto;
                right:100%;
                border-radius:100% 0 0 0;
                border-width:2em 0 0 2em;
            }
        }
        &:last-child:after {
            display:none;
        }
        @media screen and (max-width:63em){
            width:auto;
            &:after,&.right:after {
                border-radius:0;
                width:3em;
                height:2em;
                position:absolute;
                bottom:-2em;
                left:70%;
                transform:translateX(-50%);
            }
            &.right:after {
            left:30%;
            }
        }
    }

    @media screen and (max-width:50em){
        .bubbles {
            margin:0 -1em;
            .bubble {
                border-radius:1em;
                padding:1.5em 1em;
                h3 { font-size:1.2em; }
            }
        }
    }
    .inline-list {
        margin:1em auto;
        text-align:center;
    }
    .inline-list li {
        display:inline;
    }
    .inline-list li:after {
        content:'-';
        margin:0 0.5em;
    }
    .inline-list li:last-child:after {
        content:'';
        margin:0;
    }
    .mid-cta {
        text-align:center;
        max-width:40em;
        margin:4em auto 0;
        li {
            display:inline;
            margin:0 1em;
            @media screen and (max-width:40em){
                display:block;
                margin:1em auto;
            }
        }
    }
    .casestudies {
        background:#fff;
        ul li {
            display:flex;
            align-items:flex-start;
            margin:4em 0;
            img {
                max-width:12em;
                margin-right:3em;
            }
            p { margin:0 }
            @media screen and (max-width:60em){
                flex-direction:column;
                img {
                    margin-bottom:1em;
                }
            }
        }
    }





    #contact {
        background:rgb(8, 8, 7) url(/guarantee/img/pos.jpg) center center;
        background-size:cover;
        padding:2rem 0;
        color:#fff;
        position:relative;
        z-index:88;
    }
    #contact h3 {
        max-width:24em;
        margin:0 auto;
        text-align:center;
        margin-bottom:3rem;
        background:linear-gradient(160deg, rgba(55,179,55,1) 0%, rgba(55,179,96,1) 100%);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
    }
    #contact h3 span { color:#fff; -webkit-text-fill-color:#fff }
    #contact .row {
        margin-top:3rem;
        display:flex;
        width:100%;
        max-width:56rem;
        margin:0 auto;
    }
    .contact-card {
        min-width:14em;
        max-width:20em;
        background:rgba(51,51,50,0.8);
        padding:2.5rem;
        position:relative;
        padding-bottom:10em;
        flex:0;
        a {
            color:inherit;
            text-decoration:underline;
        }
        @media screen and (max-width:50em){
            padding:1.5em;
            padding-bottom:8em;
        }
    }

    .contact-card:after {
        background:rgba(255,0,0,0.25);
        display:block;
        content:'';
        position:absolute;
        bottom:0; left:0; right:0; top:0;
        z-index:0;
        background:transparent url(/guarantee/img/earth.png) bottom center no-repeat;
        background-size:contain;
    }
    .contact-card .content {
        z-index:2;
        position:relative;
    }
    .contact-card img {
        width:10em;
        max-width:100%;
    }
    .contact-card p {
        font-size:0.8em;
        margin:1em 0;
        color:#fff;
    }
    #contact .form {
        flex:1;
        margin-right:5rem;
    }
    #contact form {
        height:100%;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
    }
    #contact .success {
        box-sizing:border-box;
        border:1px solid rgba(55,179,55,1);
        color:rgba(55,179,55,1);
        padding:2rem;
        text-align:center;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        display:none;
        line-height:1.5;
    }
    #contact .success span {
        margin:0;
        display:block;
        font-size:1rem;
    }
    label {
        display:block;
    }
    label span {
        font-family:'Trade Gothic LT Std Extended';
        text-transform:uppercase;
        font-size:0.8em;
        line-height:1;
        color:#B3AAA1;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        box-sizing:border-box;
        width:100%;
        display:block;
        background:rgba(30, 30, 30, 0.85);
        border:0;
        border-bottom:0.1875rem solid #979797;
        color:#fff;
        font-family:'Trade Gothic LT Std';
        font-size:1.33rem;
        line-height:1.2;
        font-weight:200;
        padding:0.75rem 0.75rem 0.25rem 0.75rem;
        margin-bottom:1rem;
    }
    input.error, textarea.error {
        border-bottom-color:#e20;
    }
    textarea {
        flex:1;
    }

    button, .button {
        font-family:Ubuntu, sans-serif;
        margin-top:1rem;
        text-transform:uppercase;
        border-radius:2rem;
        line-height:1;
        padding:1rem 2rem;
        color:#fff;
        font-size:1.2rem;
        background:rgb(55,179,55);
        background:linear-gradient(160deg, rgba(55,179,55,1) 0%, rgba(55,179,96,1) 100%);
        border:0;
    }
    button:disabled {
        opacity:0.5;
    }


    @media screen and (max-width:50em){
        #contact .row {
            flex-direction:column;
        }
        #contact .form {
            margin:0 auto 2rem;
            max-width:100%;
            width:30em;
        }
        .contact-card {
            min-width:10em;
        }
    }
    @media screen and (max-width:50em) and (min-width:30em){
        .contact-card {
            margin:0 auto;
            box-sizing:border-box;
            width:100%;
            max-width:none;
            padding:2.5em;
        }
        .contact-card:after {
            background-position:right bottom;
            background-size:60%;
        }
    }
}