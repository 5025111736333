#careers-show{
    #tile-careers-show-posting{
        background: darken(white, 3%);
        ul{
            margin: 1em 0em 2em 2em;
            li{
                margin-bottom: 1em;
                list-style-type: disc;
                color: lighten(black, 40%);
                font-weight: 300;
                font-family: $quattrocento;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
