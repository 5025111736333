.overlay {
	display:none;
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:rgba(0,0,0,0.7);
	.modal {
		box-shadow:0 0.5em 1em rgba(0,0,0,0.5);
		background:#fff url(/7mistakes/banana.png) 250% 50% no-repeat;
		background-size:80%;
		max-width:30em;
		min-height:10em;
		margin:10em auto 0;
		padding:2em;
		position:relative;
		h1 {
			color:#000;
			margin-bottom:5rem;
			max-width:20rem;
			font-size:2em;
		}
		a.button {
			background:#d20;
			color:#fff;
			display:inline-block;
			padding:0.5em 1em;
			border-radius:1em;
		}
		@media screen and (max-width:35em){
			margin-top:4em;
			h1 {
				font-size:1.7em;
			}
			a.button {
				font-size:0.75em;
			}
		}
	}
}
.close-modal {
	position:absolute;
	top:1em;
	right:1em;
	line-height:0;
	width:1em;
	height:1em;
	&:before, &:after {
		display:block;
		content:'';
		width:1em;
		height:3px;
		background:#000;
		position:absolute;
		top:0.5em;
		margin-top:-1px;
		right:0;
		transform:rotate(-45deg);
	}
	&:after {
		transform:rotate(45deg);
	}
	span {
		display:block;
		text-indent:-999em;
	}
}