#contact{
    #tile-contact-map{
        position: relative;
        border-top: 1px solid lighten(black, 75%);
        iframe{
            width: 100%;
            height: 350px;
            border: 0;
        }
        .map-cover{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,0);
        }
    }
    #tile-contact-form{
        #form-area{
            padding-right: 2em;
            border-right: 1px solid lighten(black, 85%);

            input, textarea {
                font-family: "Ubuntu", sans-serif;
            }
        }
        aside{
            padding-left: 2em;
            font-size: 1.1em;
            a{
                color: lighten(black, 45%);
                display: block;
                margin-bottom: .5em;
                i{
                    padding-right: .5em;
                }
            }
            .row{
                margin-bottom: 1em;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        textarea{
            height: 9em;
            min-height: 5em;
        }
        .msg{
            padding: .5em;
            margin: 1em 0;

            p {
                margin: 0;
                text-align: center;
            }

            &.success {
                border: 2px solid #29a913;
                background-color: transparentize(#29a913, 0.7);
                color: #636363;
            }
            &.error {
                border: 2px solid #ff0000;
                background-color: transparentize(#ff0000, 0.7);
                color: #636363;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        #tile-contact-form{
            .block{
                padding: 0;
            }
            #form-area, aside{
                padding: 2em;
                margin-right: 0;
                margin-left: 0;
                width: 100%;
                border: none;
            }
            aside{
                padding: 1em;
                background: darken(white, 1%);
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 600px) {
        #tile-contact-form{
            .block{
                padding: 0;
            }
            #form-area{
                padding: 1em;
                .submit-btn{
                    width: 100%;
                }
            }
        }
    }
}
