#work{
    .casestudies-banner{
        text-align: center;
        padding: 1em;
        background: $dark;
        display: none;
    }
    #tile-work-examples{
        background: darken(white, 5%);
        border: 1px solid darken(white, 5%);
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-wrap: wrap;
        -webkit-flex-flow: row wrap;
        flex-wrap:wrap;
        .example{
            border: 1px solid darken(white, 5%);
            position: relative;
            background-color: #000;
            color: white;
            flex-grow: 1;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            text-align: center;
            flex-basis: 1;
            width: 30vw;
            height: 30vw;
            max-width: 50vw;
            background-size: 100%;
            background-position: center;
            ul {
                margin:0.65em auto;
                li {
                    display:inline;
                    &::before {
                        content:'/';
                        margin:0 .65em;
                    }
                    &:first-child::before {
                        display:none;
                    }
                }
            }
            .cover{
                background: rgba(0,0,0,.5);
                height: 100%;
                opacity: 0;
                cursor: pointer;
                @extend .ease;
            }
            &:hover{
                .cover{
                    @extend .ease;
                    opacity: 1;
                }
            }
            .example-info{
                @include vertical-align();
            }
            .work-title{
                order: 1;
                font-family: $oswald;
                font-weight: 500;
                font-size: 1.25em;
                text-transform: uppercase;
                margin-bottom: .25em;
            }
            .work-category{
                order: 2;
                font-size: .8em;
                font-weight: 300;
            }
        }
    }
    .casestudies-banner{
        text-align: center;
        padding: 1em;
        background: $dark;
        display: none;
    }
    @media screen and (min-width: 2300px) {
        #tile-work-examples{
            .example{
                width: 19vw;
                height: 19vw;
                max-width:20%;
            }
        }
    }
    @media screen and (min-width: 1800px) and (max-wdith: 2299px) {
        #tile-work-examples{
            .example{
                width: 24vw;
                height: 24vw;
                max-width:25%;
            }
        }
    }
    @media screen and (max-width: 800px) {
        #tile-work-examples{
            .example{
                width: 49vw;
                height: 49vw;
                max-width:50%;
            }
        }
    }
    @media screen and (max-width: 730px) {
        .casestudies-banner{
            display: block;
            max-width:none;
        }
        #filter{
            a{
                display: none;
            }
            ul{
                width: 100%;
                text-align: center;
                padding-top: 0;
                a{
                    display: block;
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        #tile-work-examples{
            .example{
                font-size: 80%;
            }
        }
    }
    @media screen and (max-width: 375px) {
        #tile-work-examples{
            .example{
                font-size: 60%;
            }
        }
    }
}
