#sitemap{
    #tile-sitemap{
        .col-left{
            padding-right: 2em;
        }
        .col-right{
            padding-left: 2em;
        }
        ul{
            li{
                margin-bottom: .5em;
                font-size: .9em;
                font-weight: 500;
                a{
                    color: lighten(black, 20%);
                    &:hover{
                        color: $green;
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
                ul{
                    margin-left: 1.5em;
                    margin-bottom: 1em;
                    li{
                        font-size: 1em;
                        margin-bottom: .75em;
                        color: lighten(black, 50%);
                        display: block;
                        font-weight: 300;
                        &:before{
                            content: "-";
                            padding-right: .25em;
                        }
                        a{
                            color: lighten(black, 50%);
                            &:hover{
                                color: black;
                            }
                        }
                        &:first-child{
                            margin-top: .75em;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 850px) {
        #tile-sitemap{
            .col-left, .col-right{
                width: 100%;
                padding: 0;
            }
            .col-right{
                margin-top: .5em;
            }
        }
    }
}
