#page-not-found {
    .site-content {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        color: #B0BEC5;
        display: table;
        font-weight: 100;
        font-family: 'Lato';

        .container {
            text-align: center;
            display: table-cell;
            vertical-align: middle;
        }

        .content {
            padding: 4em 0;
            text-align: center;
            display: inline-block;
        }

        .title {
            font-size: 156px;
        }

        .quote {
            font-size: 36px;
        }

        .explanation {
            font-size: 24px;
        }
    }
}
