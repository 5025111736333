.ease {
    transition: all 0.25s ease;
}
.fa-custom{
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 1em;
    text-align: left;
    display: inline-block;
}
.angle{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
}
.nowrap{
  display: inline-block;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe, object, embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// mixin
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}
