#press{
    #tile-press-releases{
        .press-releases{
            margin-top: 2rem;
        }
        .release{
            padding-bottom: 1.5rem;
            margin-bottom: 1.65rem;
            border-bottom: 1px solid lighten(black, 70%);
            display: block;
            width: 100%;
            .thumbnail{
                width: 6rem;
                height: 6rem;
                // background: darken(white, 20%);
                display: inline-block;
                float: left;
                img{
                    width: 100%;
                }
            }
            .info{
                padding-left: 1.5rem;
                width: auto;
                overflow: hidden;
                min-height: 4rem;
            }
            a{
                color: black;
            }
            &:last-of-type{
                margin-bottom: 0;
                padding-bottom: 1.5rem;
                border-bottom: 0;
            }
            .date{
                display: block;
                margin-bottom: .25rem;
                color: lighten(black, 40%);
                font-style: italic;
            }
            .title{
                display: block;
                font-size: 1.65rem;
                line-height: 1.75rem;
                // text-decoration: underline;
                // padding-bottom: .15rem;
                font-family: $oswald;
            }
        }
    }

    @media screen and (max-width: 800px) {
        #tile-press-releases{
            .release{
                .title{
                    font-size: 1.35rem;
                }
                .date{
                    font-size: 1rem;
                }
            }
        }
    }
}
#press-show{
    .date{
        display: block;
        margin-bottom: .5rem;
    }
    article{
        a{
            color: $green;
            display: inline-block;
            text-decoration: underline;
            font-weight: 700;
        }
        p{
            margin-bottom: 1rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
        ul{
            margin-left: 2rem;
        	font-size: 1em;
        	line-height: 160%;
        	font-family: $quattrocento;
        	color: lighten(black, 30%);
        	font-weight: 300;
            margin-bottom: .75rem;
            li{
                display: list-item;
                margin-bottom: .75rem;
                list-style-type: disc;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
