#about{
    #tile-about-header{
        img{
            padding-right: 2em;
        }
    }
    #tile-about-employees{
        border: 1px solid darken(white, 5%);
        .employee{
            @extend .ease;
            background: lighten(black, 20%);
            border: 1px solid darken(white, 5%);
            font-size: 1em;
            width: 100%;
            position: relative;
            display: flex;
            min-height: 17em;
            background-position:50% 50%;
            background-repeat:repeat-x;
           -webkit-background-size: cover;
           -moz-background-size: cover;
           -o-background-size: cover;
            background-size: cover;
            // background-position: left top;
            // background-repeat: no-repeat;
            // background-size: 115%;
            padding: 1.5em;
            .container{
                position: relative;
            }
            .switch-btn{
                margin-top: .5em;
                a{
                    text-shadow: none;
                    -moz-text-shadow: none;
                    -webkit-text-shadow: none;
                }
            }
            p{
                margin-bottom: .75em;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            &.jekyll{
                .jekyll-btn{
                    display: none;
                }
                .hyde-btn{
                    display: inline-block;
                }
                .jekyll-bio{
                    display: block;
                }
                .hyde-bio{
                    display: none;
                }
            }
            &.hyde{
                .hyde-btn{
                    display: none;
                }
                .jekyll-btn{
                    display: inline-block;
                }
                .jekyll-bio{
                    display: block;
                }
                .hyde-bio{
                    display: none;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            .employee-image{
                position: absolute;
                left: .25em;
                bottom: -1.5em;
                width: 17em;
                height: 15em;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: top center;
            }
            .hyde-photo{
                top: -.75em;
                position: absolute;
                left: -.25em;
                z-index: 9999;
                background: white;
                padding: .4em .4em 1.7em .4em;
                width: 15em;
                border-radius:  1px;
                display: none;
                @extend .shadow;
                transform: rotate(-5deg);
                -moz-transform: rotate(-5deg);
                -webkit-transform: rotate(-5deg);
                box-shadow:0 6px 3px rgba(0,0,0,0.8);
                img{
                    border: 1px solid darken(white, 5%);
                    border-radius: 1px;
                    width: 100%;
                }
            }
            .employee-info{
                // padding-left: 11em;
                margin-left: 15em;
                // background: lighten(black, 20%);
                padding: 1.5em 2em;
                background: rgba(0,0,0,.6);
                min-height: 280px;
                p{
                    color: white;
                }
                .down-icon, .up-icon{
                    color: darken(white, 60%);
                    font-size: .8em;
                    text-align: center;
                    .hide{
                        opacity: 0;
                    }
                }
            }
            .ghost-btn{
                margin-top: 1em;
            }
            .employee-name{
                font-size: .95em;
                font-family: $oswald;
                text-transform: uppercase;
                font-weight: 400;
                margin-bottom: .15em;
                font-size: 1.15em;
                color: white;
                display: block;
                @extend .text-shadow;
            }
            .employee-title{
                display: block;
                font-size: .85em;
                font-weight: 400;
                color: $green;
                margin-bottom: .25em;
                line-height: 1.15em;
                height: 2em;
                font-style: italic;
                @extend .text-shadow;
            }
            .employee-bio{
                position: relative;
                @extend .text-shadow;
                p{
                    line-height: 1.5em;
                    font-size: .85em;
                    letter-spacing: 0.02em;
                    font-family: $ubuntu;
                    color: white;
                }
            }
        }
    }
    @media screen and (max-width: 1450px) {
        #tile-about-employees{
            .employee{
                // background-size: 200%;
            }
        }
    }
    @media screen and (max-width: 1100px) {
        #tile-about-employees{
            .employee{
                .employee-image{
                    left: -1em;
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        #tile-about-employees{
            .employee{
                // background-size: 250%;
            }
        }
    }
    @media screen and (max-width: 825px) {
        #tile-about-employees{
            .employee{
                padding: 0;
                // background-size: 170%;
                .employee-info{
                    width: 100%;
                    margin-left: 0;
                    padding: 1em;
                    z-index: 5;
                    background: lighten(black, 15%);
                    z-index: 10;
                    display: block;
                    position: static;
                    .employee-title{
                        margin-bottom: .5em;
                    }
                }
                .employee-image{
                    display: block;
                    position: static;
                    margin-top: 1em;
                    top: .5em;
                    left: 0;
                    z-index: 5;
                }
                .hyde-photo{
                    margin: 1em;
                    position: static;
                    margin-bottom:-0.5em;
                    transform: rotate(-3deg);
                    -moz-transform: rotate(-3deg);
                    -webkit-transform: rotate(-3deg);
                }
            }
        }
    }
    @media screen and (max-width: 700px) {
        #tile-about-employees{
            .employee{
                .down-icon{
                    display: none;
                }
                .employee-title{
                    height: auto;
                }
                .employee-info{
                    margin-left: 0;
                    width: 100%;
                    padding:1.65em 2em;
                    .employee-bio{
                        height: auto;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 650px) {
        #tile-about-employees{
            .employee{
                // background-size: 200%;
            }
        }
    }
    @media screen and (max-width: 550px) {
        #tile-about-employees{
            .employee{
                // background-size: 275%;
                .employee-image{
                    bottom: 0;
                    margin: 0 auto;
                    margin-top: 1em;
                    width: 14em;
                    background-position: bottom;
                }
                .hyde-photo{
                    margin: 1em auto -0.5em;
                }
            }
        }
    }
    @media screen and (max-width: 400px) {
        #tile-about-employees{
            .employee{
                .employee-title, .employee-name, .employee-bio{
                    padding: 0;
                }
            }
        }
    }
}
