@keyframes scrollwebpage {
   from { background-position:50% 0; }
   5% { background-position:50% 0; }
   95% { background-position:50% 100%; }
   to { background-position:50% 100%; }
}
#tile-work-show {
    .video-wrapper {
        position:relative;
        height:0;
        padding-bottom:56.25%;
        iframe {
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
        }
    }
    .web-wrapper {
        /*
        width:36.125em;
        height:22.8125em;
        */
        padding:21.125% 13.875% 22.125%;
        background:#6d2d69 url(/img/work/laptop-ad.jpg) 50% 50% no-repeat;
        background-size: cover;
        .laptop-screen {
            padding-bottom:63.1488%;
            background:#444;
            background:transparent 50% 0/100% no-repeat;
            background-image:url(/img/work/ad-web.jpg);

            &.scrolled {
                /*background-position:50% 100%;*/
                animation-name:scrollwebpage;
                animation-duration: 18s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
        }
    }
    .packaging-wrapper {
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
    img, iframe {
        display:block;
        max-width:100%;
    }
}
.featherlight .block {
    padding:0;

    width:100%;
}
.featherlight-content {
    width:100%;
}
#work-show{
    #tile-work-wrapper{
        padding: 2em 1em 1em 1em;
        .item-wrapper{
            float: left;
            width: 80%;
            display: inline-block;
            img{
                width: 100%;
            }
        }
        .item-info{
            padding-left: 1em;
            display: inline-block;
            h2{
                font-size: 1.25em;
                margin-bottom: .5em;
                color: $green;
                font-family: $ubuntu;
            }
            h3, h4{
                color: lighten(black, 40%);
                margin-bottom: .5em;
            }
        }
    }


    @media screen and (max-width: 900px) {
        #tile-work-wrapper{
            .item-wrapper{
                width: 100%;
            }
            .item-info{
                padding-left: 0;
                margin-top: 1em;
                h4{
                    margin-bottom: 0;
                }
            }
        }
    }
}
